<template>

  <!-- 列表 -->
  <div class="table-list">
    <el-table :data="state.tableData" style="width: 100%" v-loading="state.tableLoading">
      <el-table-column fixed prop="id" label="ID" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="connect_text" label="联系方式" />
      <el-table-column prop="id_card" label="身份证号" />

      <el-table-column prop="content_detial" label="反馈信息" />
      <el-table-column prop="fx_content" label="详细内容" />


      <el-table-column prop="image_list" label="图片">
        <template #default="scoped">
          <div style="display: flex;">
            <div style="margin-right: 10px;" @click="handlePreviewImage(v)" v-for="v, k in scoped.row.image_list"
              :key="v">
              <el-avatar :size="50" shape="square" :src="v" />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scoped">
          <div class="operations">
            <el-button link type="primary" size="small" @click="handleDeleteData(scoped.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 分页 -->
  <div class="table-pagination">
    <h5>共{{ state.paginationTotal }}条数据</h5>
    <el-pagination background layout="prev, pager, next" :current-page="state.paginationPage"
      :total="state.paginationTotal" :disabled="state.paginationDisabled" @current-change="handleCurrentClick" />
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive } from "vue";
import { reqGetToushu, reqDelToushu } from "@/api";
const _ = require("lodash");

const state = reactive({
  tableLoading: false,
  tableData: [],
  tableSearchText: "",

  paginationPage: 1,
  paginationTotal: 0,
  paginationDisabled: false,
});

const handlePagingQuery = async () => {
  state.tableData = [];
  state.tableLoading = true;
  state.paginationDisabled = true;
  try {
    const res = await reqGetToushu({
      page: state.paginationPage,
      limit: 10,
    });
    state.paginationTotal = res.data.total_count;
    res.data.list.forEach((v) => {
      try {
        v.image_list = JSON.parse(v.image_list)
      } catch (error) {
        v.image_list = []
      }
      state.tableData.push(v);
    });
    console.log("总返回条数", res.data);
  } catch (error) {
    console.log(error);
  } finally {
    state.paginationDisabled = false;
    state.tableLoading = false;
  }
};

//点击页
const handleCurrentClick = function (i) {
  state.paginationPage = i;
  handlePagingQuery();
};

const handlePreviewImage = (url) => {
  window.open(url)
}

//搜索
const handleSearchUid = () => {
  state.paginationPage = 1;
  handlePagingQuery();
};

/*********************************************增删改查*********************************************/
const handleInsertData = () => {
  ElMessageBox.prompt(`请输入小区名称`, `新增小区`, {
    confirmButtonText: "新增",
    cancelButtonText: "取消",
    inputValue: "",
  }).then(async ({ value }) => {
    try {
      const res = await reqInsertArea({
        area_name: value,
      });
      console.log("新增成功", res);
      ElMessage.success("新增成功");
      handlePagingQuery();
    } catch (error) {
      console.log(error);
    } finally {
    }
  });
};

const handleDeleteData = (row) => {
  const { id } = row;
  ElMessageBox.confirm("确认删除吗,删除后不可恢复?", "删除警告", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      try {
        console.log(id);
        const res = await reqDelToushu({
          id,
        });
        console.log("删除成功", res);
        ElMessage.success("删除成功");
        handlePagingQuery();
      } catch (error) {
        console.log(error);
        ElMessage.success("删除失败");
      } finally {
      }
    })
    .catch(() => { });
};

const handleUpdateData = (row, column) => {
  if (column.property === "id") {
    return;
  }
  let _data = _.cloneDeep(row);
  ElMessageBox.prompt(`请输入${column.label}`, `修改${column.label}`, {
    confirmButtonText: "修改",
    cancelButtonText: "取消",
    inputValue: _data[column.property],
  }).then(async ({ value }) => {
    _data[column.property] = value;
    try {
      const res = await reqUpdateArea(_data);
      console.log("修改成功", res);
      ElMessage.success("修改成功");
      handlePagingQuery();
    } catch (error) {
      console.log(error);
    } finally {
    }
  });
};

handlePagingQuery();
</script>

<style lang="scss" scoped>
.table-search {
  margin-top: 16px;
  width: 300px;
}

.table-list {
  margin-top: 16px;
  min-height: 440px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;

  h5 {
    margin-right: 16px;
  }
}
</style>
